.mainContainer {
  max-height: 600px;
  overflow-y: auto;
}

.container {
  width: 187px;
  height: 120px;
  border-radius: 8px;
  background-color: #F5F5F5;
}

.link {
  text-decoration: none;
}

.image {
  width: 100%;
  height: 48px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}