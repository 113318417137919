.loadingText {
  font-size: 20px;
  color: #62646a;
  visibility: hidden;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24rem;
}

.loadingContents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.loadingBarBackground {
  width: 146px;
  height: 4px;

  background: rgba(194, 194, 194, 0.15);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.loadingBar {
  width: 100%;
  height: 100%;
  background-color: #000000;
  border-radius: 100px;

  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  25% {
    transform: translateX(0) scaleX(0.25);
  }
  50% {
    transform: translateX(25%) scaleX(0.5);
  }
  75% {
    transform: translateX(50%) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0);
  }
}
